document.addEventListener("DOMContentLoaded", () => {
  const $nav = document.getElementById("nav-top")
  const programNavOptions = $nav.querySelectorAll(
    ".dropdown-menu > a[role='menuitem']"
  )
  const $switcher = $nav.querySelector(".nav-area-switcher")
  const $programTagline = document.querySelector(".landing-tagline-program")
  const $programName = $programTagline.querySelector(
    ".landing-tagline-program-name"
  )
  let runProgramTour = true

  const onMouseover = ($option) => {
    $programName.textContent = $option.textContent
    $programName.href = $option.href
    $programTagline.classList.remove("hidden")
  }
  const onMouseout = ($option) => {
    $programTagline.classList.add("hidden")
  }
  programNavOptions.forEach(($option) => {
    $option.addEventListener("mouseover", () => {
      onMouseover($option)
    })
    $option.addEventListener("mouseout", () => {
      onMouseout($option)
    })
  })
  $switcher.addEventListener("click", () => {
    runProgramTour = false
  })

  const delay = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time))
  }

  const PROGRAM_TOUR_INTERVAL = 1500
  const programTour = async () => {
    while (true) {
      if (!runProgramTour) break

      for (let i = 0; i < programNavOptions.length; i++) {
        if (!runProgramTour) break
        $option = programNavOptions[i]
        onMouseover($option)
        await delay(PROGRAM_TOUR_INTERVAL)
        onMouseout($option)
      }
    }
  }

  programTour()
})
